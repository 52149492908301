<template>
  <panel title="Package History">
    <data-table-local
      :columns="_columns"
      :items="items"
      :viewAble="false"
      :newAble="false"
      :deleteAble="false"
      :editAble="false"
    />
  </panel>
</template>
<script>
export default {
  data() {
    return {
      items: [],
    }
  },
  async mounted() {
    try {
      this.items = await this.api.get(`/api/buyPackage/history`)
    } catch (error) {
      console.error(error)
      return ""
    }
  },
  computed: {
    _columns() {
      return [
        {
          label: "Package Name",
          field: "package_data_name",
          thClass: "text-center",
          type: "string",
        },
        {
          label: "Credit Amount",
          field: "amount",
          // tdClass: "text-right",
          thClass: "text-center",
          type: "number",
        },
        {
          label: "Price",
          field: "price",
          tdClass: "text-right",
          thClass: "text-center",
          type: "number",
        },
        {
          label: "Date",
          field: "timestamp",
          tdClass: "text-right",
          thClass: "text-center",
        },
      ]
    },
  },
}
</script>
